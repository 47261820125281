import Map from "./Map";
import "./App.css";
import Streetview from "./Streetview";
import { useState } from "react";

export default function App() {
  const [pos, setPos] = useState([-77.13951846201199, 39.077112793026686]);
  const [lon, lat] = pos;
  return (
    <>
      <Map setPos={setPos} />
      <Streetview lat={lat} lon={lon} />
    </>
  );
}
