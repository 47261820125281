import { useRef, useEffect } from "react";
import ImageLayer from "ol/layer/Image";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import BMP from "./BMP_clean.png";
import Static from "ol/source/ImageStatic";
import "./App.css";
import { transform } from "ol/proj.js";
import { getCenter } from "ol/extent.js";

type MapProps = {
  setPos: (pos: [number, number]) => void;
};

function MapWrapper({ setPos }: MapProps) {
  const mapElement = useRef<HTMLDivElement>(null);

  const projection = "EPSG:4326";
  useEffect(() => {
    if (mapElement.current === null) {
      throw new Error("map ref unexpectedly null");
    }
    const bmpLayer = new ImageLayer();
    const tracedCorners = [
      [39.0342, -77.2027],
      [39.1378, -77.1031],
      [39.1376, -77.203],
      [39.0348, -77.1027],
    ];
    const xmin = (tracedCorners[0][1] + tracedCorners[2][1]) / 2;
    const xmax = (tracedCorners[1][1] + tracedCorners[3][1]) / 2;
    const ymin = (tracedCorners[0][0] + tracedCorners[3][0]) / 2;
    const ymax = (tracedCorners[1][0] + tracedCorners[2][0]) / 2;
    const imageExtent = [xmin, ymin, xmax, ymax];

    const map = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        bmpLayer,
      ],
      view: new View({
        center: transform(getCenter(imageExtent), projection, "EPSG:3857"),
        zoom: 13,
      }),
    });
    const source = new Static({
      url: BMP,
      crossOrigin: "",
      projection,
      imageExtent: imageExtent,
      interpolate: true,
    });
    bmpLayer.setSource(source);
    map.on("click", (e) => {
      const c = transform(e.coordinate, "EPSG:3857", projection) as [
        number,
        number
      ];
      console.log(c);
      setPos(c);
    });
  }, []);

  return <div ref={mapElement} id="map"></div>;
}

function transpose([lat, lon]: [number, number]) {
  return [lon, lat];
}

export default MapWrapper;
